import { NetworkStateUI, COMMON_EVT, INTERNET, NETWORK_STATUS } from '@volterainc/common';
import io, { Socket } from 'socket.io-client';

import Networks from './networks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { StatusDescription } from './util';
import { nextOnboardingStep, previousOnboardingStep } from 'redux/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import users from 'database';
import { setGantryLeds } from './actions';

export default function Connect() {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [networks, setNetworks] = useState<NetworkStateUI | null>(null);
  const deviceSetup = useSelector((state: RootState) => state.deviceSetup);
  const dispatch = useDispatch();

  useEffect(() => {
    if (socket && networks && networks.internet === INTERNET.Online) {
      socket.emit(COMMON_EVT.client_action, setGantryLeds('SETUP_COMPLETE'));
    } else if (socket) {
      socket.emit(COMMON_EVT.client_action, setGantryLeds('SETUP'));
    }
  }, [networks, socket]);

  useEffect(() => {
    const socket = io('https://connect.myvoltera.io/myvoltera');

    socket.on('connect_error', () => {
      socket.disconnect();
      const fallBackSocket = io('https://connect.myvoltera.io/');

      fallBackSocket.on('connect', () => {
        fallBackSocket.emit(COMMON_EVT.socket_override, true);
        setSocket(fallBackSocket);
      });

      fallBackSocket.on('connect_error', (err) => {
        console.error('failed to connect to connect.myvoltera.io', err);
      });
    });

    socket.on('connect', () => {
      setSocket(socket);
    });

    return () => {
      socket?.off(COMMON_EVT.network_update);
      socket?.disconnect();
    };
  }, []);

  useEffect(() => {
    // Set up our listeners
    if (socket === null) {
      return;
    }

    socket.on(COMMON_EVT.network_update, (networks: NetworkStateUI) => {
      setNetworks(networks);
    });
    socket.emit(COMMON_EVT.network_update, (networks: NetworkStateUI) => {
      setNetworks(networks);
    });

    return () => {
      socket?.off(COMMON_EVT.network_update);
      socket?.disconnect();
    };
  }, [socket]);

  async function addToNetworkDevices() {
    if (deviceSetup) {
      const { error } = await users.searchAndAdd(deviceSetup);
      if (error) {
        console.log(error);
      }
    }
  }
  if (networks && socket) {
    const status = networks.wifi.status === NETWORK_STATUS.NoInterface ? networks.wifi.status : networks.internet;
    if (networks.internet === INTERNET.Online) {
      addToNetworkDevices();
    }
    return (
      <div>
        <h1>Connect to Wifi</h1>
        <StatusDescription status={status} />
        <Networks socket={socket} {...networks} />
        <button
          onClick={() => {
            dispatch(previousOnboardingStep());
            dispatch(previousOnboardingStep());
          }}
        >
          Go Back
        </button>
        <button disabled={networks.internet !== INTERNET.Online} onClick={() => dispatch(nextOnboardingStep())}>
          Continue
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <h1>Please Wait</h1>
        <p>We are retrieving wireless information</p>
        <FontAwesomeIcon icon={faCircleNotch} size='8x' spin />
        <p>This should only take a minute.</p>
        <button
          onClick={() => {
            dispatch(previousOnboardingStep());
            dispatch(previousOnboardingStep());
          }}
        >
          Go Back
        </button>
        {/* <button disabled={true}>Continue</button> */}
      </div>
    );
  }
}
