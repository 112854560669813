import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { RootState } from 'redux/store';

import styles from './status.module.scss';
import users from '../../database';
import { ping } from 'utils';

import nighthawk from '../../img/nighthawk-icon.png';
import Unboxing from 'components/unboxing';

enum STATUS {
  USE_DNS = 'USE_DNS',
  STARTING_UP = 'STARTING_UP',
  OFFLINE = 'OFFLINE',
}

interface Props {
  ip: string | null;
  hostname: string;
  lastSeen: number | null;
  allowRemoval: boolean;
}

export default function Status(props: Props) {
  const [status, setStatus] = useState(STATUS.OFFLINE);
  const [title, setTitle] = useState('');

  const { ip, hostname, lastSeen } = props;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let mounted = true;
    async function checkStatus() {
      // Periodically fetch the status of our devices.
      // If we can ping the server directly - awesome
      // If balena says we are online, then DNS is updating.
      let status;
      let delay;
      if ((await ping(hostname)).success) {
        status = STATUS.USE_DNS;
        delay = 30000;
        setTitle(`Device is online, connect by visiting ${hostname}.myvoltera.io`);
      } else if (await askBalena(hostname)) {
        status = STATUS.STARTING_UP; // If Balena says it is online, we may be starting up.
        delay = 5000; // Check more often since it's starting up.
        setTitle('Device is starting up and will be available shortly.');
      } else {
        status = STATUS.OFFLINE;
        setTitle('Device is offline, power ON or complete first time setup.');
        delay = 5000;
      }

      if (mounted) {
        timeout = setTimeout(checkStatus, delay);
        setStatus(status);
      }
    }

    checkStatus();
    return () => {
      clearInterval(timeout);
      mounted = false;
    };
  }, [hostname, lastSeen]);

  function onConnect() {
    // Only redirect if using DNS.
    // We could redirect to the IP, but then that becomes an unsecure connection.
    if (status === STATUS.USE_DNS) {
      window.location.href = `https://${hostname}.myvoltera.io`;
    }
  }

  return (
    <div className={styles.wrapper} title={title}>
      <div className={styles.container}>
        {props.allowRemoval ? (
          <FontAwesomeIcon
            title='Remove Device'
            className={styles.remove}
            onClick={() => users.removeDeviceFromUser(hostname)}
            icon={faTimesCircle}
            size='1x'
          />
        ) : null}

        <div className={styles.imgWrap}>
          <img src={nighthawk} alt='Nova' />
        </div>
        <div onClick={onConnect} className={status === STATUS.OFFLINE ? styles.contentNoCursor : styles.content}>
          <div>
            <h2>{hostname}</h2>
            {Description(status, lastSeen)}
          </div>
          <div>
            <span>{hostname}.myvoltera.io</span>
            <span>{ip}</span>
          </div>
        </div>
      </div>
      {Setup(status, hostname, lastSeen)}
    </div>
  );
}

function Description(status: STATUS, lastSeen: number | null) {
  switch (status) {
    case STATUS.USE_DNS:
      return (
        <p>
          Online Now <FontAwesomeIcon title='Online' className={styles.useDNS} icon={faCircle} size='1x' />
        </p>
      );

    case STATUS.STARTING_UP:
      return (
        <p>
          Starting Up{' '}
          <FontAwesomeIcon title='Online - DNS Pending' className={styles.useIp} icon={faCircle} size='1x' />
        </p>
      );

    case STATUS.OFFLINE:
      return (
        <p>
          {calcSeen(lastSeen)} <FontAwesomeIcon title='Offline' className={styles.offline} icon={faCircle} size='1x' />
        </p>
      );
  }
}

function Setup(status: STATUS, hostname: string, lastSeen: number | null) {
  const deviceSetup = useSelector((state: RootState) => state.deviceSetup);

  // Show if we haven't seen it in a while....
  const unboxing = lastSeen === null || Date.now() - lastSeen > 2 * 24 * 60 * 60 * 1000;
  if (status === STATUS.OFFLINE && !deviceSetup && unboxing) {
    return <Unboxing hostname={hostname} />;
  }
}

async function askBalena(hostname: string) {
  // Query balena for the latest info
  // TODO - Implement some type of AUTHENTICATION ... bearer token ?
  const response = await axios.post('https://us-central1-nighthawk-95d4a.cloudfunctions.net/getHostname', { hostname });
  const { is_online } = response.data;
  console.log(`Balena says ${hostname} is ${is_online ? 'online' : 'offline'}`);
  return is_online;
}

// https://stackoverflow.com/questions/19700283/how-to-convert-time-milliseconds-to-hours-min-sec-format-in-javascript
function timeConversion(millisec: number) {
  const seconds = parseFloat((millisec / 1000).toFixed(1));
  const minutes = parseFloat((millisec / (1000 * 60)).toFixed(1));
  const hours = parseFloat((millisec / (1000 * 60 * 60)).toFixed(1));
  const days = parseFloat((millisec / (1000 * 60 * 60 * 24)).toFixed(1));

  if (seconds < 60) {
    return seconds + ' sec';
  } else if (minutes < 60) {
    return minutes + ' min';
  } else if (hours < 24) {
    return hours + ' hours';
  } else {
    return days + ' days';
  }
}

function calcSeen(lastSeen: number | null) {
  if (lastSeen === null) {
    return 'New Device';
  }
  const delta = Date.now() - lastSeen;

  // If less than 2 days display hours, mins, secs
  if (delta < 2 * 24 * 60 * 60 * 1000) {
    return `Seen ${timeConversion(delta)} ago`;
  }

  // Otherwise just display date.
  const inDate = new Date(lastSeen);
  return inDate.toDateString();
}
