import { createAction, createReducer } from '@reduxjs/toolkit';
import { Device, User } from '@volterainc/common';
import { LAYOUT, ONBOARDING, stepToIndex, State, stepToName } from './types';

const initialState: State = {
  layout: LAYOUT.login,
  user: null,
  devices: [],

  emailVerified: false,
  emailNotified: false,
  deviceSetup: null,
  onboarding: ONBOARDING.overview,
};

// Account Management.
export const setUser = createAction<User | null>('setUser');
export const signOut = createAction('setSignOut');
export const setEmailAuthenticated = createAction<boolean>('setAuthenticated');
export const setEmailNotified = createAction<boolean>('setEmailNotified');

export const setLayout = createAction<LAYOUT>('setLayout');
export const addDevice = createAction<Device>('addDevice');

// Initial Device Setup
export const setDeviceSetup = createAction<string>('setDeviceSetup');

export const nextOnboardingStep = createAction('nextOnboardingStep');
export const previousOnboardingStep = createAction('previousOnboardingStep');

const mainReducer = createReducer(initialState, (builder) => {
  builder.addCase(signOut, () => initialState);

  builder.addCase(setUser, (state, action) => {
    state.user = action.payload;

    // We may have removed a device. Remove from state
    if (action.payload) {
      const filtered = state.devices.filter((device) => action.payload?.devices.includes(device.hostname));
      state.devices = filtered;
    } else {
      state.devices = [];
    }
  });

  builder.addCase(addDevice, (state, action) => {
    const filtered = state.devices.filter((device) => device.hostname !== action.payload.hostname);
    const sorted = [...filtered, action.payload].sort((a, b) => (b.lastSeen || 0) - (a.lastSeen || 0));
    state.devices = sorted;
  });

  builder.addCase(setDeviceSetup, (state, action) => {
    state.deviceSetup = action.payload;
  });

  builder.addCase(setEmailAuthenticated, (state, action) => {
    state.emailVerified = action.payload;
  });

  builder.addCase(setEmailNotified, (state, action) => {
    state.emailNotified = action.payload;
  });

  builder.addCase(setLayout, (state, action) => {
    state.layout = action.payload;

    if (action.payload === LAYOUT.mainView) {
      state.deviceSetup = null;
      state.onboarding = ONBOARDING.overview;
    }
  });

  builder.addCase(nextOnboardingStep, (state) => {
    state.onboarding = stepToName(stepToIndex(state.onboarding) + 1);
  });

  builder.addCase(previousOnboardingStep, (state) => {
    state.onboarding = stepToName(stepToIndex(state.onboarding) - 1);
  });
});

export default mainReducer;
