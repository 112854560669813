import React from 'react';
import isEmail from 'validator/lib/isEmail';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faUser, faKey, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import user from './login.module.scss';
import UserDB from '../../database';

enum STATUS {
  SignIn = 'SignIn',
  SignUp = 'SignUp',
  ResetPassword = 'ResetPassword',
  CheckMail = 'CheckMail',
}

interface State {
  _spinning: boolean;
  _error: string;
  _name: string;
  _email: string;
  _password: string;
  _passwordConfirm: string;
  _status: STATUS;
}

interface Checks {
  checkEmail?: boolean;
  checkPassword?: boolean;
  checkPasswordMatch?: boolean;
  checkName?: boolean;
}

export default class User extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      // Local state
      _name: '',
      _email: '',
      _password: '',
      _passwordConfirm: '',
      _status: STATUS.SignIn,

      _spinning: false,
      _error: '',
    };
  }

  componentDidUpdate() {
    if (this.state._spinning && this.state._error.length > 0) {
      this.setState({ _spinning: false });
    }
  }

  isDisabled(checkName: boolean, checkEmail: boolean, checkPassword: boolean, checkMatch: boolean) {
    if (checkName && this.state._name.length === 0) {
      return true;
    }

    if (checkEmail && !isEmail(this.state._email)) {
      return true;
    }

    if (checkPassword && this.state._password.length < 7) {
      return true;
    }

    if (checkMatch && this.state._password !== this.state._passwordConfirm) {
      return true;
    }
    return false;
  }

  renderError(options: Checks) {
    const { checkEmail, checkPassword, checkPasswordMatch, checkName } = options;
    if (this.state._error) {
      return <p className={user.error}>{this.state._error}</p>;
    }

    if (checkEmail && this.state._email.length > 0 && !isEmail(this.state._email)) {
      return <p className={user.error}>Please enter a valid email</p>;
    }

    if (checkPassword && this.state._password.length > 0 && this.state._password.length < 7) {
      return <p className={user.error}>Passwords must be longer than 6 characters</p>;
    }

    if (checkPasswordMatch && this.state._password !== this.state._passwordConfirm) {
      return <p className={user.error}>Passwords do not match</p>;
    }

    if (checkName && this.state._name.length < 2) {
      return <p className={user.error}>Please enter your name</p>;
    }
  }

  renderEmail() {
    return (
      <div className={user.bar}>
        <FontAwesomeIcon icon={faEnvelope} size='1x' />
        <input
          type='text'
          value={this.state._email}
          placeholder='Email address'
          onFocus={() => this.setState({ _error: '' })}
          onChange={(e) => this.setState({ _email: e.target.value })}
        />
      </div>
    );
  }

  renderName() {
    return (
      <div className={user.bar}>
        <FontAwesomeIcon icon={faUser} size='1x' />
        <input
          type='text'
          value={this.state._name}
          placeholder='Full name'
          onFocus={() => this.setState({ _error: '' })}
          onChange={(e) => this.setState({ _name: e.target.value })}
        />
      </div>
    );
  }

  renderPassword() {
    return (
      <div className={user.bar}>
        <FontAwesomeIcon icon={faKey} size='1x' />
        <input
          type='password'
          placeholder='Enter password'
          value={this.state._password}
          onFocus={() => this.setState({ _error: '' })}
          onChange={(e) => this.setState({ _password: e.target.value })}
        />
      </div>
    );
  }

  renderPasswordConfirm() {
    return (
      <div>
        <div className={user.bar}>
          <FontAwesomeIcon icon={faKey} size='1x' />
          <input
            type='password'
            placeholder='Confirm password'
            value={this.state._passwordConfirm}
            onFocus={() => this.setState({ _error: '' })}
            onChange={(e) => this.setState({ _passwordConfirm: e.target.value })}
          />
        </div>
      </div>
    );
  }
  renderResetPassword() {
    const onReset = (e: React.FormEvent) => {
      e.preventDefault();
      this.setState({ _status: STATUS.CheckMail });
      UserDB.sendPasswordResetEmail(this.state._email).catch((e) => this.setState({ _error: e.message }));
    };
    return (
      <form onSubmit={onReset}>
        <p>Please enter your email to reset your password.</p>
        {this.renderEmail()}
        {this.renderError({ checkEmail: true })}
        <div>
          <button type='submit' disabled={this.isDisabled(false, true, false, false)}>
            Reset
          </button>
        </div>
        <button type='button' onClick={() => this.setState({ _status: STATUS.SignIn })} className='muted-button'>
          Back to Sign In
        </button>
      </form>
    );
  }

  renderSignIn() {
    const onSignIn = (e: React.FormEvent) => {
      e.preventDefault();
      this.setState({ _spinning: true });
      UserDB.signIn(this.state._email, this.state._password).catch((e) => {
        console.log(e);
        this.setState({ _error: e.message });
      });
    };

    return (
      <form onSubmit={onSignIn}>
        <p>Please enter your login details to sign in.</p>
        {this.renderEmail()}
        {this.renderPassword()}
        {this.renderError({ checkEmail: true, checkPassword: true })}

        <button type='button' onClick={() => this.setState({ _status: STATUS.SignUp })} className='muted-button'>
          Sign Up
        </button>
        <button type='button' onClick={() => this.setState({ _status: STATUS.ResetPassword })} className='muted-button'>
          Forgot Password?
        </button>
        <div>
          <button type='submit' disabled={this.isDisabled(false, true, true, false)}>
            Sign In
          </button>
        </div>
      </form>
    );
  }

  renderSignUp() {
    const onSignUp = (e: React.FormEvent) => {
      e.preventDefault();
      this.setState({ _spinning: true });
      UserDB.signUp(this.state._name, this.state._email, this.state._password).catch((e) =>
        this.setState({ _error: e.message })
      );
    };
    return (
      <form onSubmit={onSignUp}>
        <p>Enter your contact information below to sign up.</p>
        {this.renderName()}
        {this.renderEmail()}
        {this.renderPassword()}
        {this.renderPasswordConfirm()}
        {this.renderError({ checkEmail: true, checkPassword: true, checkPasswordMatch: true, checkName: true })}
        <div>
          <button type='submit' disabled={this.isDisabled(true, true, true, true)}>
            Sign Up
          </button>
        </div>

        <button type='button' onClick={() => this.setState({ _status: STATUS.SignIn })} className='muted-button'>
          Go Back
        </button>
      </form>
    );
  }

  renderCheckMail() {
    return (
      <div>
        <p>Please check your email for password reset instructions.</p>
        <p>(Remember to check the spam folder)</p>
        {this.renderError({})}
        <button onClick={() => this.setState({ _status: STATUS.SignIn })}>ok</button>
      </div>
    );
  }

  renderContent() {
    if (this.state._spinning) {
      return (
        <div>
          <FontAwesomeIcon icon={faCircleNotch} size='8x' spin />
        </div>
      );
    }

    switch (this.state._status) {
      case STATUS.SignIn:
        return this.renderSignIn();

      case STATUS.SignUp:
        return this.renderSignUp();

      case STATUS.ResetPassword:
        return this.renderResetPassword();

      case STATUS.CheckMail:
        return this.renderCheckMail();

      default:
        console.log('Unknown status in sign up.');
    }
  }
  render() {
    return (
      <div className={user.wrapper}>
        <h1>Your Account</h1>

        {this.renderContent()}
      </div>
    );
  }
}
