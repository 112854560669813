import { useDispatch } from 'react-redux';
import { nextOnboardingStep, setLayout } from 'redux/reducer';
import { LAYOUT } from 'redux/types';
import overview from '../../../img/connect/overview.svg';

export default function Overview() {
  const dispatch = useDispatch();

  return (
    <div>
      <h1>New Device Setup</h1>

      <p>
        Welcome! Over the next few steps you will connect your device to the internet with a{' '}
        <strong>wireless connection</strong>.
      </p>

      <img className='diagram' src={overview} alt='' />

      <div className='center'>
        <button onClick={() => dispatch(setLayout(LAYOUT.mainView))}>Go Back</button>
        <button onClick={() => dispatch(nextOnboardingStep())}>Continue</button>
      </div>
    </div>
  );
}
