import React from 'react';

import type { Socket } from 'socket.io-client';

import { COMMON_EVT, InterfaceStatus, INTERNET, Network, NETWORK_STATUS } from '@volterainc/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEye, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { Signal, ErrorConnecting } from './util';

import styles from './wifi.module.scss';
import common from './network.module.scss';

import { wifiScan, wifiConnect } from './actions';

interface State {
  _secured: boolean;
  _password: string;
  _ssid: string;
  _selectedIndex: number;
  _masked: boolean;
  _refreshing: boolean;
  _timestamp: number | null;
}

interface Props {
  socket: Socket;

  internet: INTERNET;
  wifi: InterfaceStatus;
  ethernet: InterfaceStatus;

  current: Network | null;
  networks: Network[];
  error: string | null;
  hostname: string;

  // Hack to force an update in the UI.
  // (we only update when there are differences)
  timestamp: number | null;
}

export default class Networks extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // Local data
      _secured: false,
      _password: '',
      _ssid: '',
      _selectedIndex: 0,
      _masked: true,
      _refreshing: false,
      _timestamp: 0,
    };
  }

  componentDidUpdate() {
    if (this.state._refreshing && this.props.timestamp !== this.state._timestamp) {
      this.setState({ _refreshing: false, _selectedIndex: 0 });
    }
  }

  onConnect(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.socket.emit(
      COMMON_EVT.client_action,
      wifiConnect({ ssid: this.state._ssid, password: this.state._password })
    );
    this.setState({ _selectedIndex: 0, _password: '' });
  }

  onScan() {
    this.setState({ _refreshing: true, _timestamp: this.props.timestamp });
    this.props.socket.emit(COMMON_EVT.client_action, wifiScan());
  }
  renderDescription(status: NETWORK_STATUS) {
    switch (status) {
      case NETWORK_STATUS.Connected:
        return 'Connected';
      case NETWORK_STATUS.Hotspot:
        return 'Hotspot Active';
      case NETWORK_STATUS.NoInterface:
        return 'No Wifi Dongle Detected';
      case NETWORK_STATUS.NotConnected:
        return 'Not Connected';
      case NETWORK_STATUS.None:
        return 'Unknown Wifi Status';
      case NETWORK_STATUS.Connecting:
        return 'Connecting ...';
    }
  }
  renderNetwork(network: Network, index: number) {
    const { ssid, signal, security } = network;
    const { _selectedIndex, _refreshing } = this.state;

    // Style depending on selected index
    // And if we are _refreshing
    let style = styles.network;
    if (_selectedIndex === index + 1) {
      style = `${styles.network} ${common.highlight}`;
    }
    if (_refreshing) {
      style = `${styles.network} ${styles.refreshing}`;
    }

    return (
      <div
        key={index}
        className={style}
        onClick={() => {
          if (!_refreshing) {
            this.setState({
              _selectedIndex: index + 1,
              _ssid: ssid,
              _secured: Boolean(security),
              _password: '',
            });
          }
        }}
      >
        <span>{ssid}</span>
        <div className={styles.icons}>
          {security ? <FontAwesomeIcon icon={faLock} size='1x' /> : null}
          <Signal signal={signal} />
        </div>
      </div>
    );
  }

  renderActiveNetwork(wifi: InterfaceStatus, network: Network | null) {
    const { _selectedIndex } = this.state;

    let style = `${common.activeNetwork}`;
    if (_selectedIndex === 0) {
      style = `${common.activeNetwork} ${common.highlight}`;
    }

    // Are we even connected?

    if ((wifi.status !== NETWORK_STATUS.Connected && wifi.status !== NETWORK_STATUS.Hotspot) || !network) {
      return (
        <div className={style} onClick={() => this.setState({ _selectedIndex: 0, _password: '' })}>
          <h1>{this.renderDescription(wifi.status)}</h1>
        </div>
      );
    }

    // Either connected or in hotspot mode. Either way we should have a network.

    const { ssid, signal, security } = network;

    return (
      <div className={style} onClick={() => this.setState({ _selectedIndex: 0, _password: '' })}>
        <div>
          <h2>{ssid}</h2>
          <div className={common.infoNetwork}>
            <span>IP:{wifi.ip}</span>
            <br />
            <span>MAC:{wifi.mac}</span>
          </div>
        </div>
        <div>
          {security ? <FontAwesomeIcon icon={faLock} size='2x' /> : null}
          <Signal signal={signal} />
        </div>
      </div>
    );
  }

  renderPassword() {
    const { _masked, _password } = this.state;
    return (
      <input
        type={_masked ? 'password' : 'text'}
        id='pass'
        name='password'
        value={_password}
        onChange={(event) => this.setState({ _password: event.target.value })}
      />
    );
  }

  renderManager() {
    const { wifi, error } = this.props;
    const { _refreshing, _selectedIndex, _masked, _secured, _password } = this.state;

    // If we got a connection error - display until ack.
    if (error) {
      return <ErrorConnecting socket={this.props.socket} error={error} />;
    }

    // We are connecting - no buttons.
    if (wifi.status === NETWORK_STATUS.Connecting) {
      return (
        <div className={common.manager}>
          <p>Please Wait</p>
          <FontAwesomeIcon icon={faCircleNotch} spin size='2x' />
        </div>
      );
    }

    if (_selectedIndex === 0) {
      // If refreshing - a small spinner.
      if (_refreshing) {
        return (
          <div className={common.manager}>
            <p>{wifi.status}</p>
            <FontAwesomeIcon icon={faCircleNotch} spin size='2x' />
          </div>
        );
      }

      // Render Buttons
      return (
        <div className={common.manager}>
          <p>{this.renderDescription(wifi.status)}</p>
          <button
            hidden={wifi.status === NETWORK_STATUS.Hotspot || wifi.status === NETWORK_STATUS.NoInterface}
            onClick={() => this.onScan()}
          >
            Refresh
          </button>
        </div>
      );
    }

    // Don't render password if there is no security.
    if (!_secured) {
      return (
        <form onSubmit={(e) => this.onConnect(e)} className={common.manager}>
          <div></div>
          <button type='submit'>Connect</button>
        </form>
      );
    }

    // Disable connect if password is blank.
    return (
      <form onSubmit={(e) => this.onConnect(e)} className={common.manager}>
        <FontAwesomeIcon
          className={_masked ? `${styles.maskedIcon}` : `${styles.maskedIcon} ${styles.active}`}
          icon={faEye}
          size='1x'
          onClick={() => this.setState({ _masked: !_masked })}
        />
        {this.renderPassword()}
        <button type='submit' disabled={_password.length === 0}>
          Connect
        </button>
      </form>
    );
  }

  render() {
    const { wifi, current, networks } = this.props;
    return (
      <div className={styles.wrapper}>
        {this.renderActiveNetwork(wifi, current)}
        <div id='scrollbar' className={styles.options}>
          {networks
            .filter((x, i, a) => {
              return a.findIndex((t) => t.ssid === x.ssid) === i;
            })
            .filter((network) => network.ssid)
            .map((network, index) => {
              return this.renderNetwork(network, index);
            })}
        </div>
        {this.renderManager()}
      </div>
    );
  }
}
