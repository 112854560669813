import { defaultUser } from '@volterainc/common';
import { DeviceCollection, UserCollection } from '@volterainc/firestore';
import { merge } from 'lodash-es';
import { connectFirestore } from '@volterainc/firestore';
//
console.log(process.env.NODE_ENV);
const firebaseConfig = {
  apiKey: 'AIzaSyDtje7nJG6S9Ph_FD7Bw91y_J2KaEJcyoU',
  authDomain: 'nighthawk-95d4a.firebaseapp.com',
  databaseURL: 'https://nighthawk-95d4a.firebaseio.com',
  projectId: 'nighthawk-95d4a',
  storageBucket: 'nighthawk-95d4a.appspot.com',
  messagingSenderId: '938496224811',
  appId: '1:938496224811:web:5139c32ea44963f6c7846e',
  measurementId: 'G-MVX1LYBFJC',
};

export const {
  db,
  auth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} = connectFirestore(firebaseConfig);

type Subscription = () => void;

class Users {
  subscriptions: Subscription[];
  uc: UserCollection;
  dc: DeviceCollection;

  constructor() {
    this.subscriptions = [];
    this.uc = new UserCollection(db);
    this.dc = new DeviceCollection(db);
  }
  async signIn(email: string, password: string) {
    await signInWithEmailAndPassword(email, password);
    await this.verifyIfNeeded();
  }

  async signUp(name: string, email: string, password: string) {
    await createUserWithEmailAndPassword(email, password);
    await this.verifyIfNeeded();
    await this._createUser(name, email);
  }

  async signOut() {
    await auth.signOut();
  }

  async verifyIfNeeded() {
    // Verify the email if needed.
    if (auth.currentUser && !auth.currentUser.emailVerified) {
      await sendEmailVerification(auth.currentUser, {
        url: `https://myvoltera.io`,
      });
    }
  }

  async sendPasswordResetEmail(email: string) {
    try {
      await sendPasswordResetEmail(email, { url: `https://myvoltera.io` });
    } catch {
      //swallow errror
    }
  }

  // Create or Updates a user.
  async _createUser(name: string, email: string) {
    if (!auth.currentUser) {
      throw Error('Cannot create user. User not signed in!');
    }

    console.log(`Creating user in database: ${auth.currentUser.email}`);
    let user = merge(defaultUser(), {
      name,
      email,
    });
    await this.uc.create(user, auth.currentUser?.uid);
  }

  async getUser() {
    console.log('Fetching user');
    if (auth.currentUser) {
      return this.uc.fetch(auth.currentUser?.uid);
    }
  }

  async getDevicesFromUser() {
    console.log('Fetching user devices');
    if (auth.currentUser) {
      const user = await this.uc.fetch(auth.currentUser.uid);
      return user.devices;
    }
    return [];
  }

  async addDeviceToUser(device: string) {
    console.log('Adding new device to user');
    const existingDevices = await this.getDevicesFromUser();

    if (existingDevices.includes(device)) {
      return {
        error: `Device "${device}" has already been added to your account`,
      };
    }

    let devices = [...existingDevices, device];
    await this.uc.update({ devices }, auth.currentUser?.uid);
    return { success: true };
  }

  async removeDeviceFromUser(device: string) {
    console.log('Removing device from user');
    const existingDevices = await this.getDevicesFromUser();
    const filtered = existingDevices.filter((item: string) => item !== device);
    await this.uc.update({ devices: filtered }, auth.currentUser?.uid);
  }

  async searchAndAdd(device: string) {
    try {
      //throws a 404 error if device doesn't exits.
      await this.dc.fetch(device);
    } catch (error) {
      return { error: `Device "${device}" has not been registered!` };
    }
    return await this.addDeviceToUser(device);
  }

  getDevices(devices: string[], cb: any) {
    // Want to be notified when the device changes.

    this.subscriptions.forEach((unsubscribe) => {
      // Always unsubscribe when we re-add.
      unsubscribe();
    });

    this.subscriptions = [];

    for (let i = 0; i < devices.length; i++) {
      console.log(`Fetching device: ${devices[i]}`);

      const unsubscribe = this.dc.watch(devices[i], (device) => {
        cb(device);
      });

      this.subscriptions.push(unsubscribe);
    }
  }
}

const users = new Users();
export default users;
