import { ONBOARDING, stepToIndex } from 'redux/types';
import styles from './progressBar.module.scss';

interface Props {
  step: ONBOARDING;
}
export default function StepIndicator(props: Props) {
  const step = stepToIndex(props.step);

  return (
    <div className={styles.label}>
      <div className={styles.dots}>
        <div className={dotStyle(0, step)} />
        <div className={dotStyle(1, step)} />
        <div className={dotStyle(2, step)} />
        <div className={dotStyle(3, step)} />
        <div className={dotStyle(4, step)} />
      </div>
      <div style={barStyle(step)} className={styles.bar} />
      <div className={styles.barReference} />
    </div>
  );
}

function dotStyle(step: number, currentStep: number) {
  return step <= currentStep ? styles.activeBullet : styles.bullet;
}

function barStyle(currentStep: number) {
  return {
    transform: `scaleX(${currentStep / 4})`,
  };
}

// function clickable(step: number, currentStep: number, dispatch: Dispatch<any>) {
//   if (step <= currentStep) {
//     dispatch(setOnboardingStep(stepToName(step)));
//   }
// }
