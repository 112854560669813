import React from 'react';

import users from '../../database';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import find from './find.module.scss';

interface State {
  hostname: string;
  error: string;
}

export default class Find extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    // Pre-populate form if they type /hostname
    let path = window.location.pathname.match(/\w+/);

    this.state = {
      hostname: path ? path[0] : '',
      error: '',
    };
  }

  async componentDidMount() {
    // We can 'pre-add their device if it they get re-directed here from the app.

    if (this.state.hostname) {
      const { error } = await users.searchAndAdd(this.state.hostname);
      if (error) {
        // Swallow the error - the user didn't explitely do this.
        // Likely they have already added the unit.
        console.log(error);
      }
    }
  }

  async onFind(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    // Search our database and see if we can find anything, update profile if we find something.
    const { error } = await users.searchAndAdd(this.state.hostname);

    if (error) {
      this.setState({ error });
    }
  }

  render() {
    return (
      <div className={find.wrapper}>
        <div className='devices'>
          <h2>Add a new device</h2>
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <p>Please enter your device name to connect.</p>

        <form onSubmit={(e) => this.onFind(e)}>
          <div className={find.bar}>
            <FontAwesomeIcon icon={faSearch} size='1x' />
            <input
              name='hostname'
              onChange={(e) => this.setState({ hostname: e.target.value.toLowerCase() })}
              value={this.state.hostname}
              placeholder='Ex: AdorableAnt'
            />
          </div>
          <p className='error'>{this.state.error}</p>
          <div className='center'>
            <button type='submit'>Search</button>
          </div>
        </form>
      </div>
    );
  }
}
