import { useDispatch, useSelector } from 'react-redux';
import { previousOnboardingStep, setLayout } from 'redux/reducer';
import { RootState } from 'redux/store';
import { LAYOUT } from 'redux/types';

import checklist from '../../../img/connect/wrapUp.svg';

export default function WrapUp() {
  const deviceSetup = useSelector((state: RootState) => state.deviceSetup);
  const dispatch = useDispatch();

  return (
    <div>
      <h1>Setup Complete!</h1>

      <p>
        Your device is ready and will be available at {deviceSetup}.myvoltera.io in a
        few moments.
      </p>
      <img className='diagram' src={checklist} alt='' />
      <p>
        You can now <strong>disconnect</strong> the ethernet cable.
      </p>

      <button onClick={() => dispatch(previousOnboardingStep())}>Go Back</button>
      <button onClick={() => dispatch(setLayout(LAYOUT.mainView))}>Finish</button>
    </div>
  );
}
