import type { Device } from '@volterainc/common';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { addDevice } from 'redux/reducer';

import Login from '../login/login';
import Devices from '../devices/devices';
import Find from '../find/find';
import Profile from '../profile/profile';
import WifiSetup from '../wifiSetup/wifiSetup';

import Users from '../../database';
import styles from './layout.module.scss';

import { LAYOUT } from '../../redux/types';
import Verify from 'components/verify/verify';
import Local from 'components/local/local';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi } from '@fortawesome/free-solid-svg-icons';

export default function Layout() {
  const deviceNames = useSelector((state: RootState) => state.user?.devices);
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('Fetching Devices from Use effect');
    Users.getDevices(deviceNames || [], (device: Device) => dispatch(addDevice(device)));
  }, [deviceNames, dispatch]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Content />
      </div>
    </div>
  );
}

function Content() {
  const layout = useSelector((state: RootState) => state.layout);
  const user = useSelector((state: RootState) => state.user);
  const emailVerified = useSelector((state: RootState) => state.emailVerified);
  const emailNotified = useSelector((state: RootState) => state.emailNotified);

  // Are they logged in?
  if (!user) {
    return <Login />;
  }

  if (!emailVerified && !emailNotified) {
    return <Verify />;
  }

  if (layout === LAYOUT.showProfile) {
    return <Profile />;
  }

  if (layout === LAYOUT.deviceSetup) {
    return <WifiSetup />;
  }

  return (
    <div style={{ width: '80%' }}>
      <div className='devices'>
        <h2>Network devices</h2>
        <FontAwesomeIcon icon={faWifi} />
      </div>

      <Devices />
      <Local />
      <Find />
    </div>
  );
}
