import { auth, db } from './database';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';

import store from 'redux/store';

import Header from './components/header/header';
import Layout from './components/layout/layout';
import { setEmailAuthenticated, setUser } from 'redux/reducer';

import './css/app.scss';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { UserCollection } from '@volterainc/firestore';

let unsubUser = () => {};

const uc = new UserCollection(db);

auth.onAuthStateChanged((user) => {
  if (user) {
    store.dispatch(setEmailAuthenticated(user.emailVerified));
    console.log(`User ${user.email} signed in`);
    unsubUser = uc.watch(user.uid, (userModel) => {
      store.dispatch(setUser(userModel));
    });
  } else {
    console.log(`User signed out`);
    store.dispatch(setUser(null));
    store.dispatch(setEmailAuthenticated(false));
    unsubUser();
  }
});

export default function App() {
  const user = useSelector((state: RootState) => state.user);

  const { update, shutdown, boot } = useIntercom();

  useEffect(() => {
    if (user) {
      update({
        name: user.name,
        email: user.email,
        customAttributes: { product: 'nova' },
      });
    } else {
      shutdown();
      boot();
    }
  });

  return (
    <div>
      <Header user={user} />
      <Layout />
    </div>
  );
}
