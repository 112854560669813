import { User, Device } from '@volterainc/common';

export enum LAYOUT {
  login = 'login',
  offline = 'offline',
  showProfile = 'showProfile',
  mainView = 'mainView',
  deviceSetup = 'deviceSetup',
}

export enum ONBOARDING {
  overview = 'overview',
  checkList = 'checklist',
  confirmLocal = 'confirmLocal',
  wifiSetup = 'wifiSetup',
  wrapUp = 'wrapUp',
}

export interface State {
  layout: LAYOUT;
  user: User | null;
  devices: Device[];
  emailVerified: boolean;
  emailNotified: boolean;

  deviceSetup: string | null;
  onboarding: ONBOARDING;
}

const stepOrder = [
  ONBOARDING.overview,
  ONBOARDING.checkList,
  ONBOARDING.confirmLocal,
  ONBOARDING.wifiSetup,
  ONBOARDING.wrapUp,
];

export function stepToIndex(step: ONBOARDING) {
  return stepOrder.indexOf(step);
}

export function stepToName(stepNumber: number) {
  return stepOrder[stepNumber];
}
