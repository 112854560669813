import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import type { Device } from '@volterainc/common';
import Status from '../status/status';
import Sticker from '../sticker/sticker';

import styles from './devices.module.scss';

interface Props {
  showOnly?: string | null;
}

export default function Devices(props: Props) {
  let devices = useSelector((state: RootState) => state.devices);
  if (props.showOnly) {
    const device = devices.find((device) => device.hostname === props.showOnly);
    if (device) {
      devices = [device];
    }
  }

  return (
    <div>
      <div className={styles.devices} id='scrollbar'>
        {Items(devices, typeof props.showOnly !== 'string')}
      </div>
    </div>
  );
}

function Items(devices: Device[], allowRemoval: boolean) {
  if (devices.length === 0) {
    return <Sticker />;
  }

  return devices.map((device) => (
    <Status
      key={device.hostname}
      ip={device.ip}
      hostname={device.hostname}
      lastSeen={device.lastSeen}
      allowRemoval={allowRemoval}
    />
  ));
}
