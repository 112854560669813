import { useDispatch } from 'react-redux';
import checklist from '../../../img/connect/checklist.svg';

import { nextOnboardingStep, previousOnboardingStep } from 'redux/reducer';

export default function ConnectionCheckList() {
  const dispatch = useDispatch();

  return (
    <div>
      <h1>Setup Checklist</h1>
      <p>Take a moment to ensure that the steps below have been completed before continuing.</p>
      <ol>
        <li>Insert the wifi dongle into NOVA's USB port.</li>
        <li>Connect one end of an ethernet cable to NOVA's ethernet port.</li>
        <li>
          Connect the other end of the ethernet cable <strong>directly</strong> to your computer.
        </li>
        <li>Turn NOVA on.</li>
      </ol>

      <img className='diagram' src={checklist} alt='' />

      <div className='center'>
        <button onClick={() => dispatch(previousOnboardingStep())}>Go Back</button>
        <button onClick={() => dispatch(nextOnboardingStep())}>Continue</button>
      </div>
    </div>
  );
}
