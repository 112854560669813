import label from '../../img/sticker.svg';

import style from './sticker.module.scss';

export default function Sticker() {
  return (
    <div className={style.wrapper}>
      <div>
        <p>No devices associated with this account.</p>
        <p>The device name can be found on the sticker on the side of your device.</p>
        <p>
          In the example, the device name is <i>AdorableAnt</i>.
        </p>
      </div>
      <img src={label} alt='Example label' />
    </div>
  );
}
