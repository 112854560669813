import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { nextOnboardingStep, previousOnboardingStep, setDeviceSetup } from 'redux/reducer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ping } from 'utils';

export default function ConfirmLocal() {
  const dispatch = useDispatch();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let mounted = true;
    async function checkStatus() {
      const { success, hostname } = await ping('connect');
      if (success) {
        dispatch(setDeviceSetup(hostname));
        dispatch(nextOnboardingStep());
      }
      if (mounted) {
        timeout = setTimeout(checkStatus, 5000);
      }
    }

    checkStatus();
    return () => {
      clearInterval(timeout);
      mounted = false;
    };
  }, [dispatch]);

  return (
    <div>
      <h1>Searching for device</h1>
      <p>Ensure the ethernet cable is plugged into this computer.</p>
      <FontAwesomeIcon icon={faCircleNotch} size='8x' spin />
      <p>Contact support if you are having issues.</p>
      <button onClick={() => dispatch(previousOnboardingStep())}>Go Back</button>
    </div>
  );
}
