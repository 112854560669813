import React from 'react';

import profile from './profile.module.scss';

import users from '../../database';

// For now - Nothing to see here.
// Ability to change their name and email.

// Later
// - They could accept materials shares
// - See their data.

// What would they do here that they can't do in the webiste
// What can they do on the website that they can't do here.

export default class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
    };
  }

  onChangeEmail() {}

  onChangeName() {}

  async componentDidMount() {
    const { name, email } = await users.getUser();
    this.setState({ name, email });
  }
  render() {
    return (
      <div className={profile.wrapper}>
        <h2>Your profile</h2>
        <p>
          <strong>Name</strong> <span>{this.state.name}</span>
        </p>
        <p>
          <strong>Email</strong> <span>{this.state.email}</span>
        </p>

        <p>Todo</p>
        <ul>
          <li>Ability to change name</li>
          <li>Ability to change email</li>
          <li>Accept or Reject a share materials</li>
          <li>...what else?</li>
        </ul>
      </div>
    );
  }
}
