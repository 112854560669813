import { useSelector } from 'react-redux';

import { ONBOARDING } from 'redux/types';
import { RootState } from 'redux/store';

import StepIndicator from './progressBar/progressBar';
import Connect from './connect/connect';
import ConnectionCheckList from './checklist/checklist';
import ConfirmLocal from './confirmLocal/confirmLocal';
import WrapUp from './wrapUp/wrapUp';

import wifiSetup from './wifiSetup.module.scss';
import Overview from './overview/overview';

export default function WifiSetup() {
  const onboarding = useSelector((state: RootState) => state.onboarding);

  return (
    <div className={wifiSetup.wrapper}>
      {Content(onboarding)}
      <StepIndicator step={onboarding} />
    </div>
  );
}

function Content(onboarding: ONBOARDING) {
  switch (onboarding) {
    case ONBOARDING.overview:
      return <Overview />;
    case ONBOARDING.checkList:
      return <ConnectionCheckList />;
    case ONBOARDING.confirmLocal:
      return <ConfirmLocal />;
    case ONBOARDING.wifiSetup:
      return <Connect />;
    case ONBOARDING.wrapUp:
      return <WrapUp />;
  }
}
