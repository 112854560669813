import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEthernet, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import styles from './local.module.scss';

import { ping } from 'utils';

import nighthawk from '../../img/nighthawk-icon.png';
import { useDispatch } from 'react-redux';
import { setLayout } from 'redux/reducer';
import { LAYOUT } from 'redux/types';

enum MODE {
  Online = 'online',
  Offline = 'offline',
  Choose = 'choose',
  Setup = 'setup',
}

export default function Local() {
  const [hostname, setHostname] = useState<string | null>(null);
  const [mode, setMode] = useState(MODE.Offline);
  const dispatch = useDispatch();
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let mounted = true;
    async function checkStatus() {
      const { success, hostname, online } = await ping('connect');

      if (success) {
        setHostname(hostname);
      } else {
        setHostname(null);
      }

      if (mode !== MODE.Choose) {
        if (online) {
          setMode(MODE.Online);
        } else {
          setMode(MODE.Offline);
        }
      }

      if (mounted) {
        timeout = setTimeout(checkStatus, 10000);
      }
    }

    checkStatus();
    return () => {
      clearInterval(timeout);
      mounted = false;
    };
  }, [mode]);

  function onConnect() {
    if (mode === MODE.Online) {
      window.location.href = `https://connect.myvoltera.io`;
      return;
    }
    if (mode === MODE.Choose) {
      setMode(MODE.Offline);
    } else {
      setMode(MODE.Choose);
    }
  }

  function renderChooser() {
    if (mode === MODE.Choose) {
      return (
        <div className={styles.stayOffline}>
          <p>Complete device setup to unlock all features</p>
          <div className={styles.choose}>
            <button
              className='hyper-button'
              onClick={() => {
                setMode(MODE.Setup);
                dispatch(setLayout(LAYOUT.deviceSetup));
              }}
            >
              Setup device
            </button>
            <button className='hyper-button' onClick={() => (window.location.href = `https://connect.myvoltera.io`)}>
              Use offline
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  function renderFound() {
    return (
      <div className={styles.container} title='Device available via an ethernet connection'>
        <div className={styles.imgWrap}>
          <img src={nighthawk} alt='Nova' />
        </div>
        <div onClick={onConnect} className={styles.content}>
          <div>
            <h2>{hostname}</h2>
            <p>
              Available <FontAwesomeIcon title='Online' className={styles.useDNS} icon={faCircle} size='1x' />
            </p>
          </div>
          <div>
            <span>via Ethernet</span>
            <span>10.42.2.1</span>
          </div>
        </div>
      </div>
    );
  }

  function renderSearching() {
    return (
      <p>
        <FontAwesomeIcon icon={faCircleNotch} size='1x' spin /> Searching for devices...
      </p>
    );
  }

  return (
    <div>
      <div className='devices'>
        <h2>Wired devices</h2>
        <FontAwesomeIcon icon={faEthernet} />
      </div>

      <div className={styles.wrapper}>
        {hostname ? renderFound() : renderSearching()}
        {renderChooser()}
      </div>
    </div>
  );
}
