import type { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

import users from 'database';

import { setEmailNotified } from 'redux/reducer';

export default function Verify() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  // const [resent, setResent] = useState(false);

  // useEffect(() => {
  //   async function check() {
  //     // Periodically check if they have verified.
  //     // Need to reload the user for fresh information.
  //     await auth.currentUser?.reload();
  //     if (auth.currentUser?.emailVerified) {
  //       dispatch(setEmailAuthenticated(auth.currentUser.emailVerified));
  //     } else {
  //       setTimeout(check, 1000);
  //     }
  //   }

  //   check();
  // }, [dispatch]);

  // const onResend = () => {
  //   setResent(true);
  //   users.verifyIfNeeded(true);
  // };

  return (
    <div className='center'>
      <h1>Verify Your Email</h1>

      <p>
        An email has been sent to {user?.email}. Please click on the provided link.
      </p>

      <p>Remember to check your spam folder!</p>

      <button onClick={() => dispatch(setEmailNotified(true))}>Continue</button>
      <div>
        <button onClick={() => users.signOut()} className='muted-button'>
          Go back
        </button>
      </div>
    </div>
  );
}
