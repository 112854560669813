import type { User } from '@volterainc/common';
import logo from '../../img/logo.svg';

import header from './header.module.scss';
import users from '../../database';
import { useDispatch } from 'react-redux';
import { signOut } from 'redux/reducer';

interface Props {
  user: User | null;
}

export default function Header(props: Props) {
  const dispatch = useDispatch();
  function renderLinks() {
    if (props.user) {
      return (
        <div>
          <button
            className='muted-button'
            onClick={() => {
              dispatch(signOut());
              users.signOut();
            }}
          >
            Sign Out
          </button>
        </div>
      );
    }

    return <div />;
  }

  return (
    <div className={header.header}>
      <img id='logo' alt='Voltera Logo' src={logo} />
      {renderLinks()}
    </div>
  );
}
