import type { Socket } from 'socket.io-client';

import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEthernet, faWifi, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { COMMON_EVT, INTERNET, NETWORK_STATUS } from '@volterainc/common';
import { wifiAcknowledge } from './actions';

import signal1 from 'img/signal/signal_1.svg';
import signal2 from 'img/signal/signal_2.svg';
import signal3 from 'img/signal/signal_3.svg';
import signal4 from 'img/signal/signal_4.svg';

import util from './util.module.scss';
import network from './network.module.scss';

export function Signal({ signal }: { signal: string }) {
  let intSignal = parseInt(signal);
  if (intSignal > 75) {
    return <img src={signal4} alt={signal} />;
  } else if (intSignal > 50) {
    return <img src={signal3} alt={signal} />;
  } else if (intSignal > 25) {
    return <img src={signal2} alt={signal} />;
  } else {
    return <img src={signal1} alt={signal} />;
  }
}

export function Toggle({ wifiSelected, cb }: { wifiSelected: boolean; cb: any }) {
  return (
    <div className={util.toggle}>
      <div className={wifiSelected ? util.active : ''} onClick={() => cb(true)}>
        <FontAwesomeIcon icon={faWifi} />
        Wifi
      </div>
      <div className={wifiSelected ? '' : util.active} onClick={() => cb(false)}>
        Ethernet <FontAwesomeIcon icon={faEthernet} />
      </div>
    </div>
  );
}

export const StatusDescription = ({ status }: { status: INTERNET |NETWORK_STATUS }) => {
  switch (status) {
    case INTERNET.Online:
      return (
        <p>
          The device is connected to the <span className={util.green}>internet</span>. You may <strong>continue</strong>{' '}
          to the next step.
        </p>
      );

    case INTERNET.Portal:
      return (
        <p>
          The device is behind a <span className={util.green}>portal</span>, and has no internet conection. Contact your
          IT department or <a href='mailto: support@voltera.io'>support@voltera.io</a> for help
        </p>
      );

    case INTERNET.Limited:
    case INTERNET.None:
      return <p>The device has no internet connection. Connect to your preferred wireless network below</p>;

    default:
      return null;
  }
};

interface ErrorProps {
  socket: Socket;
  error: string;
}

export function ErrorConnecting(props: ErrorProps) {
  const { socket, error } = props;
  const [detailed, showDetailed] = useState(false);

  let message = 'Unable to connect';
  if (error.includes('Secrets were required, but not provided')) {
    message = 'Unable to connect (Incorrect Password)';
  }

  let errorDetails = detailed ? error : message;

  return (
    <div className={network.manager}>
      <p className={util.errorDetail} onClick={() => showDetailed(!detailed)}>
        <FontAwesomeIcon icon={faInfoCircle} size='1x' title={error} /> {errorDetails}
      </p>
      <button onClick={() => socket.emit(COMMON_EVT.client_action, wifiAcknowledge())}>OK</button>
    </div>
  );
}
