import unboxingIcon from '../../img/unboxing.svg';
import styles from './unboxing.module.scss';
import users from '../../database';
import { LAYOUT } from 'redux/types';
import { setDeviceSetup, setLayout } from 'redux/reducer';
import { useDispatch } from 'react-redux';

interface Props {
  hostname: string | null;
}
export default function Unboxing(props: Props) {
  const { hostname } = props;
  const dispatch = useDispatch();

  async function onDeviceSetup() {
    // On click -> Automatically take them to the first use screen.
    // Add the device to their home screen.
    if (hostname) {
      await users.searchAndAdd(hostname);
      dispatch(setDeviceSetup(hostname));
    }
    dispatch(setLayout(LAYOUT.deviceSetup));
  }

  return (
    <img
      className={styles.unboxing}
      title='Setup a new device'
      src={unboxingIcon}
      onClick={onDeviceSetup}
      alt='Setup device'
    />
  );
}
