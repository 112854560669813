import axios from 'axios';

export async function ping(hostname: string) {
  // Let errors bubble up to wrap function.
  try {
    // Alias for 10.42.2.1
    const url = `https://${hostname}.myvoltera.io/ping`;
    console.log('Pinging', url);
    const response = await axios.get(url, { timeout: 1000 });
    const { pong, online } = response.data;

    let success = true;

    // Edge case where we are pinging a DIFFERENT NOVA in the network.
    if (hostname !== 'connect') {
      success = hostname === pong;
    }

    return { success, hostname: pong, online };
  } catch (e) {
    console.log(`Could not ping ${hostname}`);
    return { success: false };
  }
}
